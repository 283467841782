<template>
    <div class="random-paper">
        <div class="head">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item @click.native="$router.back()" class="back-box">试卷管理</el-breadcrumb-item>
                <el-breadcrumb-item>创建规则组卷</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="main">
            <el-scrollbar style="height: 100%">
                <el-form :model="operatePaperParams" :rules="rules" ref="ruleForm" label-width="100px" class="form-box">
                    <el-form-item label="试卷名称" prop="name">
                        <el-input
                            type="text"
                            v-model="operatePaperParams.name"
                            placeholder="请输入试卷名称"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="设置题数">
                        <el-input
                            type="text"
                            :value="totalQuestionCount"
                            placeholder="请输入试题数"
                            autocomplete="off"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="试卷等级" prop="grade_id">
                        <el-select class="paper-level" v-model="operatePaperParams.grade_id" placeholder="请选择试卷等级">
                            <el-option
                                v-for="item in paperLevelOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="试卷总分">
                        <el-input
                            type="text"
                            placeholder="请输入试卷总分"
                            autocomplete="off"
                            maxlength="3"
                            value="100"
                            disabled
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="试卷难度" required>
                        <el-form-item prop="low">
                            <el-input
                                class="low"
                                type="number"
                                :value="operatePaperParams.low"
                                placeholder="1"
                                autocomplete="off"
                                @input="(value) => difficultyChange(value, 'low')"
                            ></el-input>
                        </el-form-item>
                        <span class="modify">~</span>
                        <el-form-item prop="high">
                            <el-input
                                class="high"
                                type="number"
                                :value="operatePaperParams.high"
                                placeholder="5"
                                autocomplete="off"
                                @input="(value) => difficultyChange(value, 'high')"
                            ></el-input>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label="题型信息" class="type-form-item" prop="theory_data">
                        <div class="set-type-question">
                            <div class="hint">(至少选择一种题型)</div>
                            <div class="type-input-list">
                                <div class="type-input-item" v-for="(item, index) in questionTypeList" :key="index">
                                    <span class="type-name"> {{ item }} </span>
                                    <el-input
                                        v-model="operatePaperParams.theory_data[index].number"
                                        type="number"
                                        placeholder="输入试题数量"
                                    ></el-input>
                                    <span class="decorate">题</span>
                                    <el-input
                                        v-model="operatePaperParams.theory_data[index].score"
                                        placeholder="输入每小题分数"
                                        type="number"
                                    ></el-input>
                                    <span class="decorate">分</span>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="选题范围" prop="cate">
                        <div class="sclect-list-box">
                            <el-cascader
                                ref="scopeCascade"
                                v-model="scopeData.value"
                                :options="scopeData.options"
                                :props="scopeData.props"
                                clearable
                                collapse-tags
                            ></el-cascader>
                            <div class="tags">
                                <div class="tag-item" v-for="(item, index) in selectLastLevelList" :key="index">
                                    <el-tag>{{ item }}</el-tag>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </el-scrollbar>
        </div>

        <div class="footer-button">
            <el-button @click="back">返回</el-button>
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </div>
    </div>
</template>

<script>
import { studentlevel, alCate, identifyOperateActualPaper } from "@/utils/apis";
export default {
    name: "randomPaper",
    data() {
        let validateScore = (rule, value, callback) => {
            let counts = value.filter((item) => item.number);
            let scores = value.filter((item) => item.score);
            if (counts.length <= 0) {
                callback(new Error("至少选择一种题型"));
            } else if (scores.length <= 0) {
                callback(new Error("必须设置分数"));
            } else if (scores.reduce((sum, item) => sum + item.score * item.number, 0) != 100) {
                callback(new Error("总分必须为100分"));
            } else {
                callback();
            }
        };
        return {
            scopeData: {
                value: [],
                options: [],
                props: { value: "id", label: "name", multiple: true },
            },
            questionTypeList: ["单选", "多选", "判断"],
            paperLevelOptions: [],
            operatePaperParams: {
                name: "",
                grade_id: "",
                cate: "",
                theory_data: [{ theory_type: 1 }, { theory_type: 2 }, { theory_type: 3 }],
                type: 2,
                low: "",
                high: "",
            },
            rules: {
                name: [
                    { required: true, message: "请输入试卷名称", trigger: "blur" },
                    { min: 3, max: 25, message: "长度必须在 3 到 25 个字符之间", trigger: "blur" },
                ],
                theory_data: [{ validator: validateScore, trigger: "blur" }],
                cate: [{ required: true, message: "请选择选题范围", trigger: "change" }],
                grade_id: [{ required: true, message: "请选择试卷等级", trigger: "change" }],
                low: [{ required: true, message: "请设置最低等级", trigger: "blur" }],
                high: [{ required: true, message: "请设置最高等级", trigger: "blur" }],
            },
        };
    },
    computed: {
        totalQuestionCount() {
            let { theory_data } = this.operatePaperParams;
            let arr = theory_data.filter((item) => item.number);
            if (arr.length <= 0) return 0;
            return arr.reduce((sum, item) => sum * 1 + item.number * 1, []);
        },
        selectLastLevelList() {
            let { value, options } = this.scopeData;
            if (value.length <= 0) return [];
            this.operatePaperParams.cate = value.map((item) => item.slice(-1)[0]).join("#");
            return this.$refs.scopeCascade
                .getCheckedNodes()
                .filter((item) => item.level >= 5)
                .map((item) => item.label);
        },
    },
    components: {},
    methods: {
        init() {
            this.getPaperLevelData();
            this.getScoreData();
        },
        async getPaperLevelData() {
            let res = await studentlevel();
            if (res.code == 200) {
                this.paperLevelOptions = res.data.map((item) => ({ label: item.level_name, value: item.grade_id }));
            }
        },
        async getScoreData() {
            let res = await alCate({ type: 3 });
            if (res.code == 200) {
                this.clearEmptySon(res.data);
                this.scopeData.options = res.data;
            }
        },
        clearEmptySon(arr) {
            arr.forEach((item) => {
                item.children.length <= 0 ? this.$delete(item, "children") : this.clearEmptySon(item.children);
            });
        },
        difficultyChange(value, prop) {
            value *= 1;
            if (value <= 5 && value >= 1) {
                this.operatePaperParams[prop] = value;
            }
        },
        back() {
            this.$router.back();
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.createRandomPaper();
                } else {
                    return false;
                }
            });
        },
        async createRandomPaper() {
            let { operatePaperParams: params } = this;
            let theory_data = JSON.stringify(params.theory_data.filter((item) => item.number && item.score));
            let res = await identifyOperateActualPaper({
                ...params,
                theory_data,
            });
            if (res.code == 200) {
                this.$message.success(res.msg);
                this.$router.back();
            } else {
                this.$message.warning(res.msg);
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
.random-paper {
    display: flex;
    flex-direction: column;
    height: 96%;
    padding: 30px;
    padding-top: 10px;
    color: #13131b;
    .head {
        margin-bottom: 30px;
        .back-box {
            cursor: pointer;
        }
        .back-box:hover ::v-deep .el-breadcrumb__inner {
            transition: all 0.3s;
            color: #2dc079;
        }
    }
    .main {
        flex: 1;
        overflow: hidden;
        .form-box {
            flex: 1;
            width: 700px;
            span {
                margin: 0 10px;
                white-space: nowrap;
            }
            .set-type-question {
                .hint {
                    color: #999;
                }
                ::v-deep .el-input {
                    width: 180px;
                }
                .type-input-list {
                    width: 555px;
                    transform: translateX(-8.5%);
                    .type-input-item:nth-child(1) {
                        margin-top: 10px;
                    }
                    .type-input-item {
                        display: flex;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .paper-level {
                width: 100%;
            }
            .sclect-list-box {
                width: 100%;
                ::v-deep .el-cascader {
                    width: 100%;
                }
            }
            .low,
            .high {
                width: 100px;
                ::v-deep input {
                    text-align: center;
                }
            }
            .tags {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }

    .footer-button {
        display: flex;
        justify-content: center;
        padding-top: 20px;
        ::v-deep .el-button {
            width: 80px;
            height: 40px;
        }
        ::v-deep .blue-button {
            background-color: #1122d8;
            border-color: #1122d8;
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-form-item__content {
    display: flex;
}
//去除number input的指示器
/* chrome */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/* 火狐浏览器 */
::v-deep input[type="number"] {
    -moz-appearance: textfield;
}
</style>
